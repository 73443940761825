import {
  List, //
  Create,
  Edit,
  EditButton,
  Datagrid,
  TextField,
  SimpleForm,
  TextInput,
  useNotify,
  Filter,
  DeleteButton,
  ReferenceInput,
  SelectInput,
  ImageField,
  DateTimeInput,
  BooleanInput,
  ImageInput,
  ExportButton,
  TopToolbar,
  Link,
  Button,
  required,
  NumberInput,
  AutocompleteInput,
  FormDataConsumer,
  CloneButton,
  Toolbar,
  ReferenceField,
  ReferenceManyField,
  SingleFieldList,
  ChipField,
  DeleteWithConfirmButton,
  useRedirect,
  useRecordContext,
} from "react-admin";

import { useHistory } from "react-router-dom";
import { useDataProvider, Loading } from "react-admin";
import { useState, useEffect, useCallback, forwardRef, useImperativeHandle, useRef } from "react";
import { Grid } from "@material-ui/core";
import { useForm, Form, Field } from "react-final-form";
import { MyDateTimeInput } from "../MyDateTimeInput";
import { Edit as EditIcon } from "@material-ui/icons";
import { MyDateField } from "../MyDateField";
import { showFormContent } from "./mpm-rows/components/show-form-content";
import { OnChange } from "react-final-form-listeners";
import { getJsonFromQuery } from "./helper";
import { RegionCheckboxGroupInput } from "./component/region-components";

const queryString = require("query-string");
var moment = require("moment-timezone");

const d = new Date();
function addZero(i) {
  if (i < 10) {
    i = "0" + i;
  }
  return i;
}
const datestring = d.getFullYear() + "-" + addZero(d.getMonth() + 1) + "-" + addZero(d.getDate()) + "T" + addZero(d.getHours()) + ":" + addZero(d.getMinutes()) + ":" + addZero(d.getSeconds()) + ".000Z";

/******************************************************************
  a list containing content_type that DO NOT has default poster
******************************************************************/
const contentTypeNoDefaultPoster = ["vodlive", "page", "horse_racecard", "horse_history", "horse_tips"];

const SlotFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Slot ID" disabled source="slot_id" alwaysOn />
  </Filter>
);

const ListActions = (props) => {
  const notify = useNotify();
  const deleteRedirect = () => `/mpm?type=${props.filterValues.type}&name=${props.filterValues.name}`;
  const onDeleteFailure = (error) => {
    notify(error.message.toString(), "warning");
  };

  //TODO: Get Editorial Name by type

  return (
    <TopToolbar>
      <div id="editorial_name" class="editorial_name">
        <label>Editorial Name: </label>
        <span>
          <b>{props.filterValues.name}</b>
        </span>
      </div>
      <DeleteWithConfirmButton mutationMode="pessimistic" record={{ id: props.filterValues.slot_id, type: props.filterValues.type }} redirect={deleteRedirect} onFailure={onDeleteFailure} />
      <Button
        color="primary"
        component={Link}
        to={{
          pathname: `mpm`,
          search: `?type=${props.filterValues.type}&name=${props.filterValues.name}`,
        }}
        label="Back"
      />
      <Button
        color="primary"
        component={Link}
        to={{
          pathname: `/slots/create`,
          search: `slot_id=${props.filterValues.slot_id}&type=${props.filterValues.type}&name=${props.filterValues.name}`,
        }}
        label="+ CREATE"
      />
      <ExportButton />
    </TopToolbar>
  );
};

export const SlotList = (props) => (
  <List {...props} sort={{ field: "start_time", order: "DESC" }} filters={<SlotFilter />} actions={<ListActions />}>
    <Datagrid>
      <TextField source="content_type" />
      <ReferenceName {...props} label="Name" />
      <ImageField class="landcape-list" source="landscape_poster" />
      <MyDateField source="start_time" showTime />
      <CloneButton label="COPY" />
      <SlotRowEditButton {...props} />
      <SlotRowDeleteButton />
    </Datagrid>
  </List>
);

const SlotRowEditButton = (props) => {
  console.log("SlotRowEditButton : props = ");
  console.log(props);

  const record = useRecordContext();

  return (
    <Button
      label="Edit"
      startIcon={<EditIcon />}
      onClick={(event) => {
        console.log("EditButton : record = ");
        console.log(record);

        var url = "/admin/#/slots/" + record.id;

        if (props.location.search) {
          url += props.location.search;
        }

        window.location.href = url;
      }}
    />
  );
};

const SlotRowDeleteButton = (props) => {
  return <DeleteWithConfirmButton {...props} redirect={false} resource="/mpm-row-schedules" mutationMode="pessimistic" confirmTitle={`Delete #${props.record.id}`} />;
};

const ReferenceName = (props) => {
  if (props.record.content_type == "programme") {
    return (
      <ReferenceField source="content_id" label="Name" reference="progammes-by-prorgamme_id" link={false}>
        <ChipField source="name_tc" />
      </ReferenceField>
    );
  } else if (props.record.content_type == "live") {
    return (
      <ReferenceField source="content_id" label="Name" reference="channels-by-network_code" link={false}>
        <ChipField source="name_tc" />
      </ReferenceField>
    );
  } else if (props.record.content_type == "clip") {
    return (
      <ReferenceField source="content_id" label="Name" reference="clip-by-clip_id" link={false}>
        <ChipField source="name_tc" />
      </ReferenceField>
    );
  }

  //prevent error when content_type is none of the above
  return <></>;
};

const SlotTitle = ({ record }) => {
  return <span>Edit Slot</span>;
};

export const SlotEdit = (props) => {
  const notify = useNotify();
  const posterRef = useRef();
  const history = useHistory();

  const onFailure = (error) => {
    notify(error.message.toString(), "warning");
  };

  // get all the query string for redirect use

  const onSuccess = ({ data }) => {
    console.log("onSuccess");
    history.goBack();
  };

  return (
    <Edit {...props} onFailure={onFailure} onSuccess={onSuccess} mutationMode="pessimistic" title={<SlotTitle />}>
      <SimpleForm redirect={false} toolbar={<Toolbar alwaysEnableSaveButton={true} />} validate={formValidate}>
        <Grid container spacing={1} fullWidth>
          <Grid item sm={12} md={6}>
            <MyDateTimeInput source="start_time" validate={required()} fullWidth />
          </Grid>
          <Grid item sm={12} md={6}>
            <BooleanInput source="enabled" defaultValue={true} fullWidth />
          </Grid>
        </Grid>

        <ContentTypeSelectInput posterRef={posterRef} />

        <FormDataConsumer>{({ formData, ...rest }) => formData.content_type == "programme" && <ProgrammeForm formData={formData} rest={rest} posterRef={posterRef} />}</FormDataConsumer>
        <FormDataConsumer>{({ formData, ...rest }) => formData.content_type == "episode" && <EpisodeForm formData={formData} rest={rest} posterRef={posterRef} />}</FormDataConsumer>
        <FormDataConsumer>{({ formData, ...rest }) => formData.content_type == "live" && <ChannelForm formData={formData} rest={rest} posterRef={posterRef} />}</FormDataConsumer>
        <FormDataConsumer>{({ formData, ...rest }) => formData.content_type == "vodlive" && <VodliveForm formData={formData} rest={rest} posterRef={posterRef} />}</FormDataConsumer>
        <FormDataConsumer>{({ formData, ...rest }) => formData.content_type == "clip" && <ClipForm formData={formData} rest={rest} posterRef={posterRef} />}</FormDataConsumer>
        <FormDataConsumer>{({ formData, ...rest }) => formData.content_type == "page" && <PageForm formData={formData} rest={rest} posterRef={posterRef} />}</FormDataConsumer>
        <FormDataConsumer>{({ formData, ...rest }) => ["horse_racecard", "horse_history", "horse_tips"].includes(formData.content_type) && <HorseForm formData={formData} rest={rest} posterRef={posterRef} />}</FormDataConsumer>
        <FormDataConsumer>{({ formData, ...rest }) => formData.content_type == "search" && <SearchForm formData={formData} rest={rest} posterRef={posterRef} />}</FormDataConsumer>

        <Grid container spacing={1} fullWidth>
          <Grid item sm={12} md={12}>
            <RegionCheckboxGroupInput />
          </Grid>
        </Grid>
      </SimpleForm>
    </Edit>
  );
};

const EpisodeSelect = (props) => {
  const dataProvider = useDataProvider();
  const [episodes, setEpisodes] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();

  useEffect(() => {
    if (!props.programme_id) {
      return;
    }

    dataProvider
      .getList("episodes", {
        pagination: { page: 1, perPage: 1000000 },
        sort: { field: "id", order: "ASC" },
        filter: { programme_id: props.programme_id },
      })
      .then(({ data }) => {
        setEpisodes(data);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  }, [props.programme_id]);

  var episodes_choices = [];

  if (episodes) {
    episodes_choices = episodes.map((episode) => ({
      id: episode.episode_id,
      name: episode.nameWithEpisodeNo,
    }));
  }

  return <SelectInput source="episode_id" choices={episodes_choices} allowEmpty={true} fullWidth />;
};

const AutoCompleteProgramme = (props) => {
  console.log("AutoCompleteProgramme : props = ");
  console.log(props);
  const form = useForm();
  const [temp, setTemp] = useState();
  return (
    <ReferenceInput
      label="Search Programme Name"
      source="programme_id"
      reference="programmes-autocomplete"
      onChange={(val) => {
        //Reset all params
        form.change("episode_id", undefined);
        form.change("content_id", undefined);
        form.change("caption_tc", undefined);
        form.change("caption_en", undefined);
        form.change("programme_name_tc", undefined);
        form.change("programme_name_en", undefined);
        form.change("landscape_poster", undefined);
        form.change("portrait_poster", undefined);

        form.change("programme_id", val);
        form.change("api_timestamp", moment().valueOf());
        setTemp(val);
      }}
      filterToQuery={(searchText) => {
        if (searchText) {
          return { name_tc: searchText };
        }
        return {};
      }}
    >
      <AutocompleteInput optionText="name_tc" optionValue="id" fullWidth />
    </ReferenceInput>
  );
};

const MyAutocompleteInput = (props) => {
  console.log("MyAutocompleteInput : props");
  console.log(props);

  // useEffect
  useEffect(() => {
    console.log("MyAutocompleteInput . useEffect : dummy = " + props.dummy);
    const temp = props.choices.map((item) => JSON.parse(JSON.stringify(item)));
    for (const tempIndex in temp) {
      props.choices[tempIndex] = temp[tempIndex];
    }
  }, [props.dummy]);

  return <AutocompleteInput {...props} />;
};

const AutoCompleteClip = (props) => {
  const form = useForm();

  return (
    <ReferenceInput
      label="Search Clip Name"
      source="clip_id"
      reference="clips-autocomplete"
      onChange={(val) => {
        //Reset all params
        form.change("content_id", undefined);

        form.change("caption_tc", undefined);
        form.change("caption_en", undefined);
        form.change("clip_name_tc", undefined);
        form.change("clip_name_en", undefined);
        form.change("landscape_poster", undefined);
        form.change("portrait_poster", undefined);

        form.change("clip_id", val);
        form.change("api_timestamp", moment().valueOf());
      }}
      filterToQuery={(searchText) => {
        if (searchText) {
          return { name_tc: searchText };
        }
        return {};
      }}
      filter={{ clip_type: props.clip_type }}
    >
      <AutocompleteInput optionText="name_tc" optionValue="clip_id" fullWidth />
    </ReferenceInput>
  );
};

const AutoCompleteTCPage = (props) => {
  const form = useForm();
  return (
    <ReferenceInput
      label="Search MPM Page Name TC"
      source="page_id"
      reference="mpm-page-autocomplete"
      onChange={(val) => {
        form.change("content_id", undefined);
        form.change("caption_tc", undefined);
        form.change("caption_en", undefined);

        form.change("api_timestamp", moment().valueOf());
      }}
      filterToQuery={(searchText) => {
        if (searchText) {
          return { name_tc: searchText };
        }
        return {};
      }}
    >
      <AutocompleteInput optionText="name_tc" optionValue="id" fullWidth />
    </ReferenceInput>
  );
};

const AutoCompleteENPage = (props) => {
  const form = useForm();
  return (
    <ReferenceInput
      label="Search MPM Page Name EN"
      source="page_id"
      reference="mpm-page-autocomplete"
      onChange={(val) => {
        form.change("content_id", undefined);
        form.change("caption_tc", undefined);
        form.change("caption_en", undefined);

        form.change("api_timestamp", moment().valueOf());
      }}
      filterToQuery={(searchText) => {
        if (searchText) {
          return { name_en: searchText };
        }
        return {};
      }}
    >
      <AutocompleteInput optionText="name_en" optionValue="id" fullWidth />
    </ReferenceInput>
  );
};

const AutoCompleteChannel = (props) => {
  const form = useForm();

  return (
    <ReferenceInput
      label="Search Channel Name"
      source="network_code"
      reference="channels-autocomplete"
      onChange={(val) => {
        form.change("content_id", undefined);
        form.change("caption_tc", undefined);
        form.change("caption_en", undefined);
        form.change("landscape_poster", undefined);
        form.change("portrait_poster", undefined);

        form.change("api_timestamp", moment().valueOf());
      }}
      filterToQuery={(searchText) => {
        if (searchText) {
          return { name_tc: searchText };
        }
        return {};
      }}
    >
      <AutocompleteInput optionText="name_tc" optionValue="id" fullWidth />
    </ReferenceInput>
  );
};

const AutoCompleteVodlive = (props) => {
  const form = useForm();
  return (
    <ReferenceInput
      label="Search VOD-Live Name"
      source="vodlive_id"
      reference="vodlives-autocomplete"
      onChange={(val) => {
        form.change("content_id", undefined);
        form.change("caption_tc", undefined);
        form.change("caption_en", undefined);
        form.change("vodlive_name_tc", undefined);
        form.change("vodlive_name_en", undefined);

        form.change("api_timestamp", moment().valueOf());
      }}
      filterToQuery={(searchText) => {
        if (searchText) {
          return { name_tc: searchText };
        }
        return {};
      }}
    >
      <AutocompleteInput optionText="name_tc" optionValue="id" fullWidth />
    </ReferenceInput>
  );
};

const FetchProgrammeData = (props) => {
  const dataProvider = useDataProvider();
  const form = useForm();

  useEffect(async () => {
    if (!props.programme_id) {
      return;
    }

    console.log(props.programme_id);
    const res = await dataProvider.getProgrammeByProgrammeId(props.programme_id);
    const programme = res.data;

    if (!programme) {
      console.log("Programme Not Found");
      return;
    }

    // const res = await dataProvider.getList("programmes", {
    //   pagination: { page: 1, perPage: 100 },
    //   sort: { field: "id", order: "ASC" },
    //   filter: { programme_id: props.programme_id.toString() },
    // });

    // if (res.data.length == 0) {
    //   return;
    // }

    // const programme = res.data[0];

    form.change("content_id", programme.programme_id);

    form.change("programme_name_tc", programme.name_tc);
    form.change("programme_name_en", programme.name_en);

    //Use caption_tc/en if exists, use programme name otherwise
    if (!props.caption_tc) {
      form.change("caption_tc", programme.name_tc);
    }
    if (!props.caption_en) {
      form.change("caption_en", programme.name_en);
    }

    form.change("landscape_poster", programme.image.landscape_large);
    form.change("portrait_poster", programme.image.portrait_large);
    if (!props.landscape_poster) {
      if (document.getElementById("pg-image")) {
        document.getElementById("pg-image").src = programme.image.landscape_large;
      } else {
        var lands = document.getElementById("landscape_poster");
        var parent = lands.parentElement;
        parent.innerHTML = '<img id="pg-image" style="margin-top:30px" width="50%" src="" />';
        parent.getElementsByTagName("img")[0].src = programme.image.landscape_large;
        lands.remove();
      }
    }

    if (!props.portrait_poster) {
      if (document.getElementById("lg-image")) {
        document.getElementById("lg-image").src = programme.image.portrait_large;
      } else {
        var port = document.getElementById("portrait_poster");
        var parentP = port.parentElement;
        parentP.innerHTML = '<img id="lg-image" style="margin-top:30px" width="30%" src="" />';
        parentP.getElementsByTagName("img")[0].src = programme.image.portrait_large;
        port.remove();
      }
    }

    // show default posters
    form.change("showDefaultPortraitPoster", true);
    form.change("showDefaultLandscapePoster", true);
  }, [props.programme_id, props.api_timestamp]);

  return null;
};

const FetchEpisodeData = (props) => {
  const dataProvider = useDataProvider();
  const form = useForm();

  useEffect(async () => {
    if (!props.episode_id) {
      return;
    }

    console.log(props.episode_id);

    const res = await dataProvider.getList("episodes", {
      pagination: { page: 1, perPage: 100 },
      sort: { field: "id", order: "ASC" },
      filter: { episode_id: props.episode_id },
    });

    if (res.data.length == 0) {
      return;
    }

    const episode = res.data[0];

    const res2 = await dataProvider.getProgrammeByProgrammeId(episode.programme_id);
    const programme = res2.data;

    form.change("content_id", episode.episode_id);

    // if (!props.caption_tc) {
    if (moment(episode.episode_no, "YYYYMMDD", true).isValid()) {
      const x = moment(episode.episode_no, "YYYYMMDD");
      form.change("caption_tc", programme.name_tc + " " + x.format("YYYY/MM/DD"));
    } else if (isNaN(episode.episode_no)) {
      form.change("caption_tc", programme.name_tc + " " + episode.name_tc);
    } else {
      form.change("caption_tc", programme.name_tc + " 第" + episode.episode_no + "集");
    }
    // }
    // if (!props.caption_en) {
    if (moment(episode.episode_no, "YYYYMMDD", true).isValid()) {
      const x = moment(episode.episode_no, "YYYYMMDD");
      form.change("caption_en", programme.name_en + " " + x.format("YYYY/MM/DD"));
    } else if (isNaN(episode.episode_no)) {
      form.change("caption_en", programme.name_en + " " + episode.name_en);
    } else {
      form.change("caption_en", programme.name_en + " Episode " + episode.episode_no);
    }
    // }

    form.change("landscape_poster", episode.image.large);
    form.change("portrait_poster", episode.image.large);
    if (document.getElementById("pg-image")) {
      document.getElementById("pg-image").src = episode.image.large;
    } else {
      var lands = document.getElementById("landscape_poster");
      var parent = lands.parentElement;
      parent.innerHTML = '<img id="pg-image" style="margin-top:30px" width="50%" src="" />';
      parent.getElementsByTagName("img")[0].src = episode.image.large;
      lands.remove();
    }

    if (document.getElementById("lg-image")) {
      document.getElementById("lg-image").src = episode.image.large;
    } else {
      var port = document.getElementById("portrait_poster");
      var parentP = port.parentElement;
      parentP.innerHTML = '<img id="lg-image" style="margin-top:30px" width="30%" src="" />';
      parentP.getElementsByTagName("img")[0].src = episode.image.large;
      port.remove();
    }

    // show default posters
    form.change("showDefaultPortraitPoster", true);
    form.change("showDefaultLandscapePoster", true);
  }, [props.episode_id, props.api_timestamp]);

  return null;
};

const FetchChannelData = (props) => {
  const dataProvider = useDataProvider();
  const form = useForm();

  useEffect(async () => {
    if (!props.network_code) {
      return;
    }

    const res = await dataProvider.getList("channels", {
      pagination: { page: 1, perPage: 100 },
      sort: { field: "id", order: "ASC" },
      filter: { network_code: props.network_code.toString() },
    });

    if (res.data.length == 0) {
      return;
    }

    const channel = res.data[0];

    form.change("content_id", channel.network_code);

    form.change("channel_name_tc", channel.name_tc);
    form.change("channel_name_en", channel.name_en);

    //Use caption_tc/en if exists, use programme name otherwise
    if (!props.caption_tc) {
      form.change("caption_tc", channel.name_tc);
    }
    if (!props.caption_en) {
      form.change("caption_en", channel.name_en);
    }

    form.change("landscape_poster", channel.landscape_poster);
    form.change("portrait_poster", channel.portrait_poster);

    if (!props.landscape_poster) {
      if (document.getElementById("pg-image")) {
        document.getElementById("pg-image").src = channel.landscape_poster;
      } else {
        var lands = document.getElementById("landscape_poster");
        var parent = lands.parentElement;
        parent.innerHTML = '<img id="pg-image" style="margin-top:30px" width="50%" src="" />';
        parent.getElementsByTagName("img")[0].src = channel.landscape_poster;
        lands.remove();
      }
    }

    if (!props.portrait_poster) {
      if (document.getElementById("lg-image")) {
        document.getElementById("lg-image").src = channel.portrait_poster;
      } else {
        var port = document.getElementById("portrait_poster");
        var parentP = port.parentElement;
        parentP.innerHTML = '<img id="lg-image" style="margin-top:30px" width="30%" src="" />';
        parentP.getElementsByTagName("img")[0].src = channel.portrait_poster;
        port.remove();
      }
    }

    // show default posters
    form.change("showDefaultPortraitPoster", true);
    form.change("showDefaultLandscapePoster", true);
  }, [props.network_code, props.api_timestamp]);

  return null;
};

const FetchVodliveData = (props) => {
  const dataProvider = useDataProvider();
  const form = useForm();

  useEffect(async () => {
    if (!props.vodlive_id) {
      return;
    }

    console.log(props.id);

    const res = await dataProvider.getList("vod-lives", {
      pagination: { page: 1, perPage: 100 },
      sort: { field: "id", order: "ASC" },
      filter: { _id: props.vodlive_id.toString() },
    });

    if (res.data.length == 0) {
      return;
    }

    const vodlive = res.data[0];

    form.change("content_id", vodlive.id);

    form.change("vodlive_name_tc", vodlive.name_tc);
    form.change("vodlive_name_en", vodlive.name_en);

    //Use caption_tc/en if exists, use programme name otherwise
    if (!props.caption_tc) {
      form.change("caption_tc", vodlive.name_tc);
    }
    if (!props.caption_en) {
      form.change("caption_en", vodlive.name_en);
    }
  }, [props.vodlive_id, props.api_timestamp]);

  return null;
};

const FetchClipData = (props) => {
  const dataProvider = useDataProvider();
  const form = useForm();

  useEffect(async () => {
    if (!props.clip_id) {
      return;
    }

    console.log(props.clip_id);

    const res = await dataProvider.getList("clips", {
      pagination: { page: 1, perPage: 100 },
      sort: { field: "id", order: "ASC" },
      filter: { clip_id: props.clip_id.toString() },
    });

    if (res.data.length == 0) {
      return;
    }

    const clip = res.data[0];

    form.change("content_id", clip.clip_id);

    form.change("clip_type", clip.type);
    form.change("clip_name_tc", clip.name_tc);
    form.change("clip_name_en", clip.name_en);

    //Use caption_tc/en if exists, use programme name otherwise
    if (!props.caption_tc) {
      form.change("caption_tc", clip.name_tc);
    }
    if (!props.caption_en) {
      form.change("caption_en", clip.name_en);
    }

    form.change("landscape_poster", clip.image.landscape_large);
    form.change("portrait_poster", clip.image.portrait_large);

    if (!props.landscape_poster) {
      if (document.getElementById("pg-image")) {
        document.getElementById("pg-image").src = clip.image.landscape_large;
      } else {
        var lands = document.getElementById("landscape_poster");
        var parent = lands.parentElement;
        parent.innerHTML = '<img id="pg-image" style="margin-top:30px" width="50%" src="" />';
        parent.getElementsByTagName("img")[0].src = clip.image.landscape_large;
        lands.remove();
      }
    }

    if (!props.portrait_poster) {
      if (document.getElementById("lg-image")) {
        document.getElementById("lg-image").src = clip.image.portrait_large;
      } else {
        var port = document.getElementById("portrait_poster");
        var parentP = port.parentElement;
        parentP.innerHTML = '<img id="lg-image" style="margin-top:30px" width="30%" src="" />';
        parentP.getElementsByTagName("img")[0].src = clip.image.portrait_large;
        port.remove();
      }
    }

    // show default posters
    form.change("showDefaultPortraitPoster", true);
    form.change("showDefaultLandscapePoster", true);
  }, [props.clip_id, props.api_timestamp]);

  return null;
};

const FetchPageData = (props) => {
  const dataProvider = useDataProvider();
  const form = useForm();

  useEffect(async () => {
    if (!props.page_id) {
      return;
    }

    console.log(props.page_id);

    const res = await dataProvider.getList("mpm-pages", {
      pagination: { page: 1, perPage: 100 },
      sort: { field: "id", order: "ASC" },
      filter: { _id: props.page_id.toString() },
    });

    if (res.data.length == 0) {
      return;
    }

    const page = res.data[0];

    form.change("content_id", page.id);
  }, [props.page_id, props.api_timestamp]);

  return null;
};

const CaptionFields = () => {
  return (
    <Grid container spacing={1} fullWidth>
      <Grid item sm={12} md={6}>
        <TextInput id="caption_tc_name" label="Caption in TC" source="caption_tc" fullWidth validate={required()} />
      </Grid>
      <Grid item sm={12} md={6}>
        <TextInput id="caption_en_name" label="Caption in EN" source="caption_en" fullWidth validate={required()} />
      </Grid>
    </Grid>
  );
};

const UploadImageFields = forwardRef((props, ref) => {
  const form = useForm();
  const record = useRecordContext();

  console.log("UploadImageFields : form = ");
  console.log(showFormContent(form));

  const defaultShowCustomPortraitPoster = (() => {
    var result = record.portrait_poster_custom ? true : false;
    console.log("defaultShowCustomPortraitPoster = " + result);
    return result;
  })();

  const defaultShowCustomLandscapePoster = (() => {
    var result = record.landscape_poster_custom ? true : false;
    console.log("defaultShowCustomLandscapePoster = " + result);
    return result;
  })();

  // const [hasCustomPortraitPoster, setHasCustomPortraitPoster] = useState(defaultShowCustomPortraitPoster);
  // const [hasCustomLandscapePoster, setHasCustomLandscapePoster] = useState(defaultShowCustomLandscapePoster);

  const hasCustomPortraitPoster = form.getState().values.hasCustomPortraitPoster;
  const hasCustomLandscapePoster = form.getState().values.hasCustomLandscapePoster;

  const showCustomPortraitPoster = useCallback(() => {
    var result = defaultShowCustomPortraitPoster ? hasCustomPortraitPoster : false;
    return result;
  });

  const showCustomLandscapePoster = useCallback(() => {
    var result = defaultShowCustomLandscapePoster ? hasCustomLandscapePoster : false;
    return result;
  });

  const showDefaultPortraitPoster = useCallback(() => {
    if (contentTypeNoDefaultPoster.includes(form.getState().values.content_type)) return false;
    var result = showCustomPortraitPoster() ? false : form.getState().values.showDefaultPortraitPoster ? true : false;
    return result;
  });

  const showDefaultLandscapePoster = useCallback(() => {
    if (contentTypeNoDefaultPoster.includes(form.getState().values.content_type)) return false;
    var result = showCustomLandscapePoster() ? false : form.getState().values.showDefaultLandscapePoster ? true : false;
    return result;
  });

  const resetPortraitPoster = useCallback(() => {
    console.log("resetPortraitPoster");
    form.change("portrait_poster_base64", null);
    form.change("portrait_poster_custom", null);
    form.change("hasCustomPortraitPoster", false);
  });

  const resetLandscapePoster = useCallback(() => {
    console.log("resetLandscapePoster");
    form.change("landscape_poster_base64", null);
    form.change("landscape_poster_custom", null);
    form.change("hasCustomLandscapePoster", false);
  });

  const onChangeProgramme = (val, old) => {
    console.log("onChangeProgramme");
    resetPortraitPoster();
    resetLandscapePoster();
  };

  useImperativeHandle(ref, () => ({
    resetPoster() {
      console.log("UploadImageFields . ref : resetPoster");

      resetPortraitPoster();
      resetLandscapePoster();

      // also reset the default program poster
      form.change("portrait_poster", undefined);
      form.change("landscape_poster", undefined);
      form.change("showDefaultPortraitPoster", false);
      form.change("showDefaultLandscapePoster", false);
    },
  }));

  return (
    <>
      {/* type="hidden" */}
      <Field name="showDefaultPortraitPoster" component="input" type="hidden" defaultValue={true} />
      <Field name="showDefaultLandscapePoster" component="input" type="hidden" defaultValue={true} />
      <Field name="hasCustomPortraitPoster" component="input" type="hidden" defaultValue={defaultShowCustomPortraitPoster} />
      <Field name="hasCustomLandscapePoster" component="input" type="hidden" defaultValue={defaultShowCustomLandscapePoster} />

      <OnChange name="api_timestamp">{onChangeProgramme}</OnChange>
      <OnChange name="programme_id">{onChangeProgramme}</OnChange>

      <Grid container spacing={1} fullWidth>
        <Grid item xs={12} sm={12} md={12}>
          <h3>Posters</h3>
        </Grid>
      </Grid>
      <Grid container spacing={1} fullWidth>
        {/* Portrait Section */}
        <Grid item xs={12} sm={12} md={6}>
          <Grid container spacing={1} fullWidth>
            <Grid item xs={12} sm={12} md={12} style={showDefaultPortraitPoster() ? {} : { display: "none" }}>
              <ImageField id="portrait_poster" label={<LabelImagePortrait />} source="portrait_poster" fullWidth />
            </Grid>
            <Grid item xs={12} sm={12} md={12} style={showCustomPortraitPoster() ? {} : { display: "none" }}>
              <ImageField id="portrait_poster_custom" label={<CustomLabelImagePortrait />} source="portrait_poster_custom" fullWidth />
            </Grid>
            <Grid item xs={12} sm={12} md={12} style={showCustomPortraitPoster() ? {} : { display: "none" }}>
              <Button label="Reset to default Portrait Poster" variant="outlined" onClick={resetPortraitPoster} />
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <ImageInput source="portrait_poster_base64" label={<LabelImagePortrait />} accept="image/*">
                <ImageField source="src" title="title" />
              </ImageInput>
            </Grid>
          </Grid>
        </Grid>

        {/* Landscape Section */}
        <Grid item sm={12} md={6}>
          <Grid container spacing={1} fullWidth>
            <Grid item xs={12} sm={12} md={12} style={showDefaultLandscapePoster() ? {} : { display: "none" }}>
              <ImageField id="landscape_poster" label={<LabelImageLandscape />} source="landscape_poster" fullWidth />
            </Grid>
            <Grid item xs={12} sm={12} md={12} style={showCustomLandscapePoster() ? {} : { display: "none" }}>
              <ImageField id="landscape_poster_custom" label={<CustomLabelImageLandscape />} source="landscape_poster_custom" fullWidth />
            </Grid>
            <Grid item xs={12} sm={12} md={12} style={showCustomLandscapePoster() ? {} : { display: "none" }}>
              <Button label="Reset to default Landscape Poster" variant="outlined" onClick={resetLandscapePoster} />
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <ImageInput source="landscape_poster_base64" label={<LabelImageLandscape />} accept="image/*">
                <ImageField source="src" title="title" />
              </ImageInput>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
});

const UploadImageInputs = () => {
  return <></>;
};

const StartTime = ({ formData, rest }) => {
  let slot_str = document.location;

  const dataProvider = useDataProvider();
  const form = useForm();

  const d = new Date();
  const addZero = (i) => {
    return i < 10 ? "0" + i : i;
  };

  // start_time + 1 day
  // let datestring = d.getFullYear() + "-" + addZero(d.getMonth() + 1) + "-" + addZero(d.getDate()) + "T" + addZero(d.getHours()) + ":" + addZero(d.getMinutes()) + ":" + addZero(d.getSeconds()) + ".000Z";
  if (slot_str.href.split("start_time").length > 1) {
    let url = decodeURIComponent(slot_str);
    let start_time = url.split('"start_time":"')[1];

    let start_date = start_time.split('",')[0];
    let start_date2 = moment(start_date);
    let datestring = start_date2.add(1, "days");
    let string1 = start_date2.format("YYYY-MM-DDTHH:mm");

    form.change("start_time", string1);
  }

  return <MyDateTimeInput disabled={false} source="start_time" validate={required()} fullWidth />;
};

const formValidate = (values) => {
  console.log("VALIDATE");
  console.log(values);
  const errors = {};

  if (values.content_type == "programme") {
    // programme dont need custom upload

    // content id
    if (!values.content_id && !values.programme_id) {
      errors.content_id = "Required";
    }
  } else if (values.content_type == "live") {
    // programme dont need custom upload

    // content id
    if (!values.content_id && !values.network_code) {
      errors.content_id = "Required";
    }
  } else if (values.content_type == "vodlive") {
    // content id
    if (!values.content_id && !values.vodlive_id) {
      errors.content_id = "Required";
    }
    // need custom upload
    if (!values.landscape_poster_base64 && !values.landscape_poster_custom) {
      errors.landscape_poster_base64 = "Required";
    }
  } else if (values.content_type == "clip") {
    // programme dont need custom upload

    // content id
    if (!values.content_id && !values.clip_id) {
      errors.content_id = "Required";
    }
  } else if (values.content_type == "episode") {
    // programme dont need custom upload

    // content id
    if (!values.content_id && !values.episode_id) {
      errors.content_id = "Required";
    }
  } else if (values.content_type == "page") {
    // content id
    if (!values.content_id && !values.episode_id) {
      errors.content_id = "Required";
    }

    // programme need custom upload
    if (!values.landscape_poster_base64 && !values.landscape_poster_custom) {
      errors.landscape_poster_base64 = "Required";
    }
  } else {
    if (!values.landscape_poster_base64 && !values.landscape_poster && !values.landscape_poster_custom) {
      errors.landscape_poster_base64 = "Required";
    }
  }

  // Portrait is not mandatory
  // if (!values.portrait_poster_base64 && !values.portrait_poster) {
  //   errors.portrait_poster_base64 = "Required";
  // }

  console.log(errors);
  return errors;
};

const ContentTypeSelectInput = (props) => {
  const form = useForm();

  return (
    <Grid container spacing={1} fullWidth>
      <Grid item sm={12} md={12}></Grid>
      <Grid item sm={12} md={12}>
        <SelectInput
          source="content_type"
          choices={[
            { id: "programme", name: "Programme" },
            { id: "live", name: "Live" },
            { id: "vodlive", name: "VOD-Live" },
            { id: "clip", name: "Clip" },
            { id: "episode", name: "Episode" },
            { id: "page", name: "Page" },
            { id: "horse_racecard", name: "Horse Racecard" },
            { id: "horse_history", name: "Horse History" },
            { id: "horse_tips", name: "Horse Tips" },
            { id: "search", name: "Search" },
          ]}
          defaultValue="programme"
          fullWidth
          onChange={(e, val) => {
            console.log("content_type onChange");
            form.change("landscape_poster", undefined); //Reset param
            form.change("portrait_poster", undefined); //Reset param
            form.change("content_id", null); //Reset param

            // trigger custom poster reset
            console.log("posterRef = ");
            console.log(props.posterRef);
            props.posterRef.current.resetPoster();
          }}
        />
      </Grid>
    </Grid>
  );
};
export const SlotCreate = (props) => {
  const notify = useNotify();
  const posterRef = useRef();
  const redirectTo = useRedirect();

  const parsed = queryString.parse(props.location.search);

  var filter = {};
  var id;
  var type;

  if (parsed) {
    //Clone
    if (parsed.source) {
      const j = JSON.parse(parsed.source);
      console.log("j = ", j);

      id = j.slot_id;
      type = j.type;
      filter = {
        slot_id: j.slot_id,
        type: j.type,
        name: j.name,
      };
    } else {
      //Normal create
      id = parsed.slot_id;
      type = parsed.type;
      filter = {
        slot_id: parsed.slot_id,
        type: parsed.type,
        name: parsed.name,
      };
    }
  }
  // get all the query string for redirect use
  // const search = props.location.search ? props.location.search : "";

  // console.log("search");
  // console.log(search);
  // var filter = {};

  // if (search) {
  //   let data = search.split("&");
  //   let slotId = data[0].split("slot_id=")[1];
  //   let type = data[1].split("type=")[1];
  //   let name = data[2].split("name=")[1];

  //   filter = {
  //     slot_id: slotId,
  //     type: type,
  //     name: name,
  //   };

  //   console.log("filter");
  //   console.log(filter);
  // }

  const onSuccess = ({ data }) => {
    console.log("onSuccess : data = ");
    console.log(data);
    const url = `/slots?filter=${JSON.stringify(filter)}`;
    console.log("onSuccess : url = " + url);
    redirectTo(url);
  };

  const onFailure = (error) => {
    notify(error.message.toString(), "warning");
  };

  // const parsed = queryString.parse(props.location.search);
  // var id = parsed.slot_id;
  // var type = parsed.type;

  // console.log("parsed");
  // console.log(type);

  return (
    <Create {...props} onSuccess={onSuccess} onFailure={onFailure} title="Create Slot">
      <SimpleForm method="POST" toolbar={<Toolbar alwaysEnableSaveButton={true} />} validate={formValidate}>
        <Grid item fullWidth>
          <NumberInput disabled type="number" source="slot_id" defaultValue={id} fullWidth />
        </Grid>

        <Grid container spacing={1} fullWidth>
          <Grid item sm={12} md={6}>
            <FormDataConsumer>{({ formData, ...rest }) => <StartTime formData={formData} rest={rest} />}</FormDataConsumer>
          </Grid>
          <Grid item sm={12} md={6}>
            <BooleanInput source="enabled" defaultValue={true} fullWidth />
          </Grid>
        </Grid>

        <ContentTypeSelectInput posterRef={posterRef} />

        <FormDataConsumer>{({ formData, ...rest }) => formData.content_type == "programme" && <ProgrammeForm formData={formData} rest={rest} posterRef={posterRef} />}</FormDataConsumer>
        <FormDataConsumer>{({ formData, ...rest }) => formData.content_type == "episode" && <EpisodeForm formData={formData} rest={rest} posterRef={posterRef} />}</FormDataConsumer>
        <FormDataConsumer>{({ formData, ...rest }) => formData.content_type == "live" && <ChannelForm formData={formData} rest={rest} posterRef={posterRef} />}</FormDataConsumer>
        <FormDataConsumer>{({ formData, ...rest }) => formData.content_type == "vodlive" && <VodliveForm formData={formData} rest={rest} posterRef={posterRef} />}</FormDataConsumer>
        <FormDataConsumer>{({ formData, ...rest }) => formData.content_type == "clip" && <ClipForm formData={formData} rest={rest} posterRef={posterRef} />}</FormDataConsumer>
        <FormDataConsumer>{({ formData, ...rest }) => formData.content_type == "page" && <PageForm formData={formData} rest={rest} posterRef={posterRef} />}</FormDataConsumer>
        <FormDataConsumer>{({ formData, ...rest }) => ["horse_racecard", "horse_history", "horse_tips"].includes(formData.content_type) && <HorseForm formData={formData} rest={rest} posterRef={posterRef} />}</FormDataConsumer>
        <FormDataConsumer>{({ formData, ...rest }) => formData.content_type == "search" && <SearchForm formData={formData} rest={rest} posterRef={posterRef} />}</FormDataConsumer>

        <Grid container spacing={1} fullWidth>
          <Grid item sm={12} md={12}>
            <RegionCheckboxGroupInput />
          </Grid>
        </Grid>
      </SimpleForm>
    </Create>
  );
};

const ProgrammeForm = ({ formData, rest, posterRef }) => {
  console.log("ProgrammeForm : formData = ");
  console.log(formData);
  formData.portrait_poster = null;
  formData.landscape_poster = null;

  console.log("ProgrammeForm : rest = ");
  console.log(rest);
  return (
    <Grid container spacing={1} fullWidth>
      <FetchProgrammeData {...formData} programme_id={formData.programme_id ?? formData.content_id} />
      <Grid item sm={12} md={6}>
        <AutoCompleteProgramme clearAlwaysVisible={true} fullWidth {...rest} allowEmpty={true} />
      </Grid>
      <Grid item sm={12} md={6}>
        <TextInput label="Content ID" source="content_id" disabled fullWidth />
      </Grid>
      <CaptionFields fullWidth />
      {/* <ProgrammeInput fullWidth /> */}

      <UploadImageFields ref={posterRef} />

      <Field name="programme_name_tc" component="input" type="hidden" />
      <Field name="programme_name_en" component="input" type="hidden" />
      <TypeHiddenField value={formData.type} />
      <Field name="api_timestamp" component="input" type="hidden" />
    </Grid>
  );
};

const EpisodeForm = ({ formData, rest, posterRef }) => {
  formData.portrait_poster = null;
  formData.landscape_poster = null;

  return (
    <Grid container spacing={1} fullWidth>
      <FetchEpisodeData {...formData} episode_id={formData.episode_id ?? formData.content_id} />
      <Grid item sm={12} md={4}>
        <AutoCompleteProgramme clearAlwaysVisible={true} fullWidth {...rest} allowEmpty={true} />
      </Grid>
      <Grid item sm={12} md={4}>
        <EpisodeSelect programme_id={formData.programme_id} {...rest} fullWidth />
      </Grid>
      <Grid item sm={12} md={4}>
        <TextInput label="Content ID" source="content_id" disabled fullWidth />
      </Grid>
      <CaptionFields fullWidth />

      <UploadImageFields ref={posterRef} />

      <TypeHiddenField />
      <Field name="programme_name_tc" component="input" type="hidden" />
      <Field name="programme_name_en" component="input" type="hidden" />
      <Field name="api_timestamp" component="input" type="hidden" />
    </Grid>
  );
};

const ChannelForm = ({ formData, rest, posterRef }) => {
  formData.portrait_poster = null;
  formData.landscape_poster = null;

  return (
    <Grid container spacing={1} fullWidth>
      <FetchChannelData {...formData} network_code={formData.network_code ?? formData.content_id} />
      <Grid item sm={12} md={6}>
        <AutoCompleteChannel clearAlwaysVisible={true} fullWidth {...rest} allowEmpty={true} />
      </Grid>
      <Grid item sm={12} md={6}>
        <TextInput label="Content ID" source="content_id" disabled fullWidth />
      </Grid>
      <CaptionFields fullWidth />

      <UploadImageFields ref={posterRef} />

      <TypeHiddenField />
      <Field name="channel_name_tc" component="input" type="hidden" />
      <Field name="channel_name_en" component="input" type="hidden" />
      <Field name="api_timestamp" component="input" type="hidden" />
    </Grid>
  );
};

const VodliveForm = ({ formData, rest, posterRef }) => {
  console.log("VodliveForm : formData = ");
  console.log(formData);
  formData.portrait_poster = null;
  formData.landscape_poster = null;

  return (
    <Grid container spacing={1} fullWidth>
      <FetchVodliveData {...formData} vodlive_id={formData.vodlive_id ?? formData.content_id} />
      <Grid item sm={12} md={6}>
        <AutoCompleteVodlive clearAlwaysVisible={true} fullWidth {...rest} allowEmpty={true} />
      </Grid>
      <Grid item sm={12} md={6}>
        <TextInput label="Content ID" source="content_id" disabled fullWidth />
      </Grid>
      <CaptionFields fullWidth />
      {/* <VodliveInput fullWidth /> */}

      <UploadImageFields ref={posterRef} />

      <TypeHiddenField />
      <Field name="vodlive_name_tc" component="input" type="hidden" />
      <Field name="vodlive_name_en" component="input" type="hidden" />
      <Field name="api_timestamp" component="input" type="hidden" />
    </Grid>
  );
};

const ClipForm = ({ formData, rest, posterRef }) => {
  formData.portrait_poster = null;
  formData.landscape_poster = null;

  return (
    <Grid container spacing={1} fullWidth>
      <FetchClipData {...formData} clip_id={formData.clip_id ?? formData.content_id} />
      <Grid item sm={12} md={12}>
        <ReferenceInput label="Clip Type" source="clip_type" reference="clips-type" fullWidth>
          <SelectInput optionText="name" optionValue="id" />
        </ReferenceInput>
      </Grid>
      <Grid item sm={12} md={6}>
        <AutoCompleteClip clip_type={formData.clip_type} clearAlwaysVisible={true} fullWidth {...rest} allowEmpty={true} />
      </Grid>
      <Grid item sm={12} md={6}>
        <TextInput label="Content ID" source="content_id" disabled fullWidth />
      </Grid>
      <CaptionFields fullWidth />

      <UploadImageFields ref={posterRef} />

      <TypeHiddenField />
      <Field name="clip_name_tc" component="input" type="hidden" />
      <Field name="clip_name_en" component="input" type="hidden" />
      <Field name="api_timestamp" component="input" type="hidden" />
    </Grid>
  );
};

const PageForm = ({ formData, rest, posterRef }) => {
  formData.portrait_poster = null;
  formData.landscape_poster = null;

  return (
    <Grid container spacing={1} fullWidth>
      <FetchPageData {...formData} page_id={formData.page_id ?? formData.content_id} />
      <Grid item sm={12} md={6}>
        <AutoCompleteTCPage clearAlwaysVisible={true} fullWidth {...rest} allowEmpty={true} />
      </Grid>
      <Grid item sm={12} md={6}>
        <AutoCompleteENPage clearAlwaysVisible={true} fullWidth {...rest} allowEmpty={true} />
      </Grid>
      <Grid item sm={12} md={12}>
        <TextInput label="Content ID" source="content_id" disabled fullWidth />
      </Grid>
      <CaptionFields fullWidth />

      <UploadImageFields ref={posterRef} />

      {/* <PageInput fullWidth /> */}
      <TypeHiddenField />
      <Field name="api_timestamp" component="input" type="hidden" />
    </Grid>
  );
};

const HorseForm = ({ formData, rest, posterRef }) => {
  formData.portrait_poster = null;
  formData.landscape_poster = null;

  return (
    <Grid container spacing={1} fullWidth>
      <CaptionFields fullWidth />

      <UploadImageFields ref={posterRef} />

      <TypeHiddenField />
    </Grid>
  );
};

const SearchForm = ({ formData, rest, posterRef }) => {
  formData.portrait_poster = null;
  formData.landscape_poster = null;

  return (
    <Grid container spacing={1} fullWidth>
      <Grid item sm={12} md={12}>
        <TextInput label="Search keyword" source="search_keyword" validate={required()} fullWidth />
      </Grid>

      <CaptionFields fullWidth />

      <UploadImageFields ref={posterRef} />

      <TypeHiddenField />
    </Grid>
  );
};

const TypeHiddenField = ({ value }) => {
  if (!value) {
    const json = getJsonFromQuery(document.location.href);

    if (json.type) {
      value = json.type;
    }
  }

  return <Field name="type" component="input" type="hidden" defaultValue={value} />;
};

const LabelImagePortrait = (props) => {
  let slot_str = document.location;
  let type = "";
  if (slot_str.href.split("type=").length > 1) {
    type = slot_str.href.split("type=")[1];
  }
  if (type == "horserace_2") {
    return "Portrait";
  } else {
    return "Portrait - 200 x 290";
  }
};

const LabelImageLandscape = (props) => {
  let slot_str = document.location;
  let type = "";
  if (slot_str.href.split("type=").length > 1) {
    type = slot_str.href.split("type=")[1];
  }
  if (type == "horserace_2") {
    return "Landscape only: 1920 x 1080";
  } else {
    return "Landscape - 615 x 346";
  }
};

const CustomLabelImagePortrait = (props) => {
  let slot_str = document.location;
  let type = "";
  if (slot_str.href.split("type=").length > 1) {
    type = slot_str.href.split("type=")[1];
  }
  if (type == "horserace_2") {
    return "Custom Portrait";
  } else {
    return "Custom Portrait - 200 x 290";
  }
};

const CustomLabelImageLandscape = (props) => {
  let slot_str = document.location;
  let type = "";
  if (slot_str.href.split("type=").length > 1) {
    type = slot_str.href.split("type=")[1];
  }
  if (type == "horserace_2") {
    return "Custom Landscape only: 1920 x 1080";
  } else {
    return "Custom Landscape - 615 x 346";
  }
};
